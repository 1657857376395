import React from "react";
import LoaderLogo from './../images/logo-SZ.png'

const Loader = () => {
  return (
    <>
      <div className="demo-container" >
        <div className="progress-bar">
          <div className="progress-bar-value"></div>
        </div>
        <div className="loader-logo">
          <img alt={LoaderLogo} src={LoaderLogo} />
        </div>
      </div>
    </>
  );
};

export default Loader;
