import { useState } from 'react';
import { useHistory } from 'react-router-dom';


function ManageToken() {
    const history = useHistory();

    const getToken = () => {
        return localStorage.getItem('token');
    };
    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        console.log("userToken");
        console.log(userToken);
        localStorage.setItem('token', userToken);
        setToken(userToken);

    };

    return {
        setToken: saveToken,
        token
    }
}
export default ManageToken;