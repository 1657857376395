import React from 'react'
import { Form, Input, Button, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';

import logo from '../images/logo-SZ.png';
import Axios from 'axios';
const SERVICE_URL = window._env_.REACT_APP_SERVICE_URL;

export default function ForgotPassword(props) {
    const history = useHistory();
    const error = (response) => {
        message.error(response);
    };
    const success = (response) => {
      message.success(response);
    };
    

    const onFinish = (values) => {
        // console.log('Received values of form: ', values);
          const res = Axios.post(
            `${SERVICE_URL}password/username`,values)
            .then(function(response)  {
            
              if(response.data.status == 'success') {
                success(response.data.message); 
              } else {
              //  alert("else "+response.data.message)
                error(response.data.message); 
              }
            })
            .catch(er => {
             // alert("catch "+error);
             // error(er); 
            //  console.log(er)
            });
    };

    return (
        <div className="login-main">
        <div className="loginbg">
            <div className="loginmn">
              <div className="loginlogo">
                <img src={logo} alt="" />
                <p className="v-text">Admin Panel 2.0</p>
              </div>
              <Form
              name="normal_login"
              className="login-form"
              initialValues={{
                  remember: true,
              }}
              onFinish={onFinish}
              >
                <div className="form-label">
                  <h4>Forgot Password</h4>
                </div>
              <Form.Item
                  name="username"
                  rules={[
                  // {
                  //   type: 'email',
                  //   message: 'The input is not valid Username!',
                  // },
                  {
                      required: true,
                      message: 'Please input your Username!',
                  },  
                  ]}
              >
                  <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                  
              </Form.Item>
                         

              <Form.Item className="form-bottom-info">
                  <Button style={{float:"left"}} type="primary" htmlType="submit" className="login-form-button">
                   Reset Password
                  </Button>
                  <Link to="/">Back To Login</Link>
              </Form.Item>
              
              </Form>
               
            </div>
        </div>
    </div>
    )
}
